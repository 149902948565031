.navbar {
    position: fixed;
    bottom: 20px;
    left: 20px;
    right: 20px;
    background-color: #5a3d08;
    padding: 10px 0;
    z-index: 1000;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.nav-list {
    display: flex;
    justify-content: space-around;
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.nav-item {
    flex: 1;
    text-align: center;
}

.icon-badge-container {
    position: relative;
    display: inline-block;
  }
  
  .badge {
    position: absolute;
    top: -8px;
    right: -8px;
    background-color: red;
    color: white;
    border-radius: 50%;
    padding: 2px 6px;
    font-size: 0.7em;
    font-weight: bold;
  }

.nav-link {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    color: #fff;
    font-size: 0.8rem;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    margin: 0;
    font-family: inherit;
}

.nav-link:focus {
    outline: none;
}

.nav-link svg {
    font-size: 1.2rem;
    margin-bottom: 4px;
}

/* Responsive adjustments */
@media (min-width: 768px) {
    .navbar {
        bottom: 30px;
        left: 50px;
        right: 50px;
    }

    .nav-link {
        font-size: 1rem;
    }

    .nav-link svg {
        font-size: 1.5rem;
    }
}